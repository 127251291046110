<template>
  <div class="relationships-section">
    <h2 class="section-title">Relationships</h2>
    <div class="mandatory-note">
      <p>
        Text boxes marked as
        <span class="mandatory">*</span> are mandatory. Control or Significant
        influence in relationship is selected, then Ownership % is mandatory.
      </p>
    </div>
    <div class="required-error" v-if="relationshipEvd.errorMessage">
      Please upload evidence for Relationships
    </div>
    <div class="required-error" v-if="controlValidationError">
      {{ controlValidationError }}
    </div>
    <div class="relationship-form">
      <div class="local-loader-container" v-if="loading">
        <div class="inava-loader"></div>
      </div>
      <div class="column-view odd-grid">
        <div class="search-field">
          <DMPSearchSelect
            label="SGA ID (12 digits)/Request ID/Entity Name"
            field="sgaId"
            :data="sgaIdData"
            :isButtonRequired="true"
            @onChange="onChangeSgaIdOrEntityId"
            :submitted="submitted"
            :isMandatory="true"
            :setValue="setValue"
            @closeDropdownPopup="closeDropdownPopup"
            @getEntitySGADetails="getEntitySGADetails"
            @addEntityClicked="showAddEntityAddDialog = true"
            :notFoundMessage="'Entity Not Found'"
            :isLoading="isLoadingSgaID"
          ></DMPSearchSelect>
        </div>
      </div>
      <div class="column-view">
        <MultiSelectorVue
          label="Relationship"
          placeholder="Select"
          :selected="selectedRelationshipTypeId"
          :list="relationshipsType"
          :submitted="submitted"
          :isMandatory="true"
          :applyPlaceholderColor="!selectedRelationshipTypeId.length"
          @onChange="onChangeAttributeSelection"
          ref="multiselectComponent"
        />
      </div>
      <div
        class="column-view checkbox-container"
        v-show="isMandatoryCheckPassed()"
      >
        <label> Majority control/SI</label>
        <input
          type="checkbox"
          v-model="isOwnershipOptional"
          @change="changeOwnershpStatus()"
        />
      </div>

      <div
        v-show="isMandatoryCheckPassed() && !isOwnershipOptional"
        class="column-view ownership-container"
      >
        <NumberField
          inputId="ownership"
          label="Ownership %"
          @onChange="onChangeOwnership"
          :isControlSelected="isControlSelected"
          :isSignificantSelected="isSignificantSelected"
          :isMandatory="true"
          :submitted="submitted"
          :value="percentageOwnership"
          ref="numberFieldComponent"
        />
      </div>
      <div class="column-view">
        <div class="search-field">
          <DMPSearchSelect
            label="Immediate Parent"
            field="immediateParent"
            :data="immediateParentData"
            :isButtonRequired="true"
            :setValue="setValue"
            :isMandatory="true"
            :submitted="submitted"
            @getEntitySGADetails="getEntitySGADetails"
            @closeDropdownPopup="closeDropdownPopup"
            @onChange="onChangeImmediateParent"
            @addEntityClicked="showAddEntityAddDialog = true"
            :notFoundMessage="'Immediate Parent Not Found'"
            :isLoading="isLoadingImmediateParent"
          >
          </DMPSearchSelect>
        </div>
      </div>
      <div class="column-view">
        <!-- <div class="search-field"> -->
        <DMPSearchSelect
          label="Ultimate Parent"
          field="ultimateParent"
          :data="ultimateParentData"
          :isButtonRequired="true"
          :setValue="setValue"
          :isMandatory="true"
          :submitted="submitted"
          @getEntitySGADetails="getEntitySGADetails"
          @closeDropdownPopup="closeDropdownPopup"
          :notFoundMessage="'Ultimate Parent Not Found'"
          @addEntityClicked="showAddEntityAddDialog = true"
          @onChange="onChangeUltimateParnt"
          :isLoading="isLoadingUltimateParent"
        ></DMPSearchSelect>
      </div>
      <div class="column-view evidence-container">
        <div class="add-evidence">
          <AddEvidence
            :hasEvidenceButton="true"
            :evidenceFormStatus="getEvidenceFormStatus(relationshipEvd.key)"
            @click.native="toggleEvidence(relationshipEvd.key)"
            :btnLoading="getBtnLoadingStatus(relationshipEvd.key)"
            :isDisabled="isEvidenceBtnDisabled()"
            :formName="'Relationship'"
          />
        </div>
        <div class="add-relation-button">
          <b-button
            @click="addRelation()"
            :disabled="!isValidForm"
            :class="isValidForm ? 'active-button' : 'button'"
            >Add Relation</b-button
          >
        </div>
      </div>
    </div>

    <div class="workflow-container">
      <RelationshipTable
        @deleteRelationship="deleteRelationshipById"
        @toggleEvidenceForm="toggleEvidenceForm"
        :columns="columns"
        :data-items="relationshipData"
        :show-toolbar="false"
        :isSubmittedEntity="isSubmittedEntity"
        :loading="loading"
      />
    </div>

    <div class="action-buttons">
      <ActionButton
        :isSaveButton="false"
        :isBackButton="true"
        @goBack="goBack"
        :isSaveAndNextButton="false"
      >
      </ActionButton>
    </div>
    <template>
      <ConfirmationDialog
        :visible="confirmationDialogData.visible"
        :title="confirmationDialogData.title"
        :bodyText="confirmationDialogData.text"
        :cancelButtonText="confirmationDialogData.cancelButtonText"
        :confirmButtonText="confirmationDialogData.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="confirmationDialogData.triggerFunction"
      />
    </template>
    <template>
      <AddEntityDialog
        :visible="showAddEntityAddDialog"
        :closeDialog="closeEntityAddDialog"
      />
    </template>
  </div>
</template>

<script>
import ActionButton from '@/components/DMP/ActionButton.vue'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import DMPSearchSelect from '@/components/DMP/DMPSearchSelect.vue'
import NumberField from '@/components/DMP/NumberField.vue'
import MultiSelectorVue from '@/components/Dropdowns/MultiSelector.vue'
import Snackbar from '@/components/Snackbar'
import { activeStepIndexName, relationshipTypeObj } from '@/constant/data.js'
import RelationshipTable from '@/views/DataManagement/Relationships/RelationshipsTable.vue'

import AddEntityDialog from '@/components/DMP/AddEntityDialog.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'relationshipForm',
  components: {
    MultiSelectorVue,
    RelationshipTable,
    AddEvidence,
    NumberField,
    ActionButton,
    ConfirmationDialog,
    AddEntityDialog,
    DMPSearchSelect
  },
  props: {
    updateStepper: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      enrichmentObj: null,
      sgaId: null,
      submitted: false,
      loading: false,
      parentEntities: [],
      ultimateParentEntities: [],
      openField: false,
      selectedSgaID: null,
      selectedEntityId: '',
      filter: null,
      entityName: null,
      selectedRelationshipTypeId: [],
      dmpSearchText: '',
      showAddEntityAddDialog: false,
      entityId: null,
      parentId: null,
      ultimateParentId: null,
      percentageOwnership: null,
      isOwnershipOptional: false,
      isNotValidOwnershipField: false,
      columns: [
        {
          field: 'sgaId',
          title: 'SGA ID',
          sortable: true,
          width: 150,
          cell: 'sgaIdTemplate'
        },
        {
          field: 'requestId',
          title: 'Request ID',
          sortable: true,
          width: 150
        },
        {
          field: 'legalEntityName',
          title: 'Legal Entity Name',
          resizable: true,
          sortable: true,
          className: 'text-center',
          width: 250
        },
        {
          field: 'countryReg',
          title: 'Country Of Registration',
          resizable: true,
          sortable: true,
          width: 190
        },
        {
          field: 'countryDom',
          title: 'Country Of Domicile',
          resizable: true,
          sortable: true,
          width: 170
        },
        {
          field: 'immediateParent',
          title: 'Immediate Parent Name',
          sortable: true,
          width: 200
        },
        {
          field: 'ultimateParent',
          title: 'Ultimate Parent Name',
          sortable: true,
          width: 200
        },
        {
          field: 'relationship',
          title: 'Relationship Type',
          filter: 'numeric',
          sortable: true,
          width: 250
        },
        {
          field: 'ownership',
          title: 'Ownership %',
          sortable: true,
          width: 120
        },
        {
          field: 'Evidence',
          title: 'Evidence',
          width: '150px',
          cell: 'viewEvidenceTemplate',
          sortable: false

          // columnMenu: false
        },
        {
          field: 'Action',
          title: 'Action',
          width: '70px',
          cell: 'actionButtonTemplate',
          sortable: false

          // columnMenu: false
        }
      ],

      snackbarPayload: {
        // Set default payload
        message: null,
        type: 'is-warning',
        duration: 3000
      },
      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },
      inputNameMapping: {},
      relationshipEvd: {
        key: 'Relationships Evidence',
        errorMessage: false
      },
      setValue: false,
      isSignificantSelected: false,
      isControlSelected: false,
      isControlAlreadyAdded: false,
      controlValidationError: ''
    }
  },
  computed: {
    ...mapState('relationship', [
      'relationshipData',
      'relationshipsType',
      'sgaIdData',
      'immediateParentData',
      'ultimateParentData',
      'isLoadingSgaID',
      'isLoadingImmediateParent',
      'isLoadingUltimateParent'
    ]),
    ...mapState('evidence', ['evidenceIdList']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails']),
    ...mapState('assignedEntities', ['isSubmittedEntity']),
    isEvdFieldFilled() {
      this.relationshipEvd.errorMessage =
        this.relationshipEvd.errorMessage || this.submitted
          ? !this.evidenceIdList.includes(this.relationshipEvd.key) &&
            !isEmpty(this.selectedEntityId) &&
            !isEmpty(this.selectedRelationshipTypeId)
          : false
      return (
        !isEmpty(this.selectedEntityId) &&
        !isEmpty(this.selectedRelationshipTypeId)
      )
    },
    isValidForm() {
      return this.isEvdFieldFilled && !this.isSubmittedEntity
    }
  },
  watch: {
    evidenceIdList: {
      handler() {
        // When evidence is uploaded then remove evidence message
        this.relationshipEvd.errorMessage =
          this.relationshipEvd.errorMessage || this.submitted
            ? !this.evidenceIdList.includes(this.relationshipEvd.key)
            : false
      },
      deep: true
    },
    isControlSelected() {
      this.percentageOwnership = null
      this.$refs.numberFieldComponent.clearMessage()
    },
    relationshipData() {
      this.isControlAlreadyAdded =
        this.relationshipData &&
        this.relationshipData.length > 0 &&
        this.relationshipData.some((relation) =>
          relation.relationship?.includes(relationshipTypeObj.CONTROL)
        )
    }
  },
  async mounted() {
    this.getFormDetails()
  },

  methods: {
    ...mapActions('relationship', [
      'getRelationships',
      'deleteRelationships',
      'getRelationshipsType',
      'addRelationships',
      'getDataForSgaId',
      'getDataForImmediateParentId',
      'getDataForUltimateParentId',
      'getDataForEntityDropdown',
      'updateDataForSgaID',
      'updateDataForImmediateParent',
      'updateDataForUltimateParent'
    ]),
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore',
      'removeEvidence'
    ]),
    ...mapActions('assignedEntities', ['checkAndUpdateTodoStatus']),
    ...mapActions('relationship', ['updateHierarchyView']),

    async getFormDetails() {
      try {
        this.resetEvidenceStore()
        this.loading = true
        const { sgaId } = this.getEntityDetails

        this.sgaId = sgaId
        this.getRelationships({
          sgaId: sgaId
        })
        this.getRelationshipsType()
        const entityObj = {
          searchQuery: '',
          field: ''
        }
        this.getDataForEntityDropdown(entityObj)
        this.updateDataForSgaID([])
        this.updateDataForImmediateParent([])
        this.updateDataForUltimateParent([])
      } finally {
        this.loading = false
      }
    },

    closeDropdownPopup() {
      // Closes the dropdown popup of the multiselect component
      this.$refs.multiselectComponent.onClickOutside()
    },

    changeOwnershpStatus() {
      if (this.isOwnershipOptional) {
        this.percentageOwnership = 999
      } else {
        this.percentageOwnership = null
      }
    },
    async getEntitySGADetails(searchQuery, field) {
      const entityObj = {
        searchQuery: searchQuery,
        field: field
      }
      if (field === 'sgaId') {
        await this.getDataForEntityDropdown(entityObj)
      } else if (field === 'immediateParent') {
        await this.getDataForEntityDropdown(entityObj)
      } else if (field === 'ultimateParent') {
        await this.getDataForEntityDropdown(entityObj)
      }
    },
    onChangeAttributeSelection(data) {
      this.selectedRelationshipTypeId = data
      if (
        this.selectedRelationshipTypeId.some(
          (type) => type.name === relationshipTypeObj.CONTROL
        )
      ) {
        this.isControlSelected = true
      } else {
        this.isControlSelected = false
      }
      if (
        this.selectedRelationshipTypeId.some(
          (type) => type.name === relationshipTypeObj.SIGNIFICANTINFLUENCE
        )
      ) {
        this.isSignificantSelected = true
      } else {
        this.isSignificantSelected = false
      }

      this.openField = true
      this.isMandatoryCheckPassed()
    },
    goBack() {
      this.updateStepper(activeStepIndexName.aedetails)
    },
    onChangeSgaIdOrEntityId(enrichmentObj) {
      if (enrichmentObj) {
        this.enrichmentObj = enrichmentObj
        this.selectedEntityId = enrichmentObj.entityId
        this.selectedSgaID = enrichmentObj.sgaId
        this.entityName = enrichmentObj.text
      } else {
        this.enrichmentObj = null
      }
      this.isMandatoryCheckPassed()
      this.removeEvidence(this.relationshipEvd.key)
    },
    onChangeOwnership(percentageOwnership, isNotValid) {
      this.percentageOwnership = percentageOwnership
      this.isNotValidOwnershipField = isNotValid
      this.removeEvidence(this.relationshipEvd.key)
    },
    onChangeImmediateParent(enrichmentObj) {
      this.parentId = enrichmentObj?.entityId
      this.removeEvidence(this.relationshipEvd.key)
    },
    onChangeUltimateParnt(enrichmentObj) {
      this.ultimateParentId = enrichmentObj?.entityId
      this.removeEvidence(this.relationshipEvd.key)
    },
    async addRelation() {
      this.submitted = true
      if (
        !this.selectedSgaID ||
        !this.ultimateParentId ||
        !this.parentId ||
        !this.selectedRelationshipTypeId ||
        this.selectedRelationshipTypeId.length === 0 ||
        !this.controlValidations()
      ) {
        return
      }
      if (this.isValidForm && !this.isValidEvidence()) {
        return
      }

      const mandatoryCheckPassed = this.isMandatoryCheckPassed()
      if (
        mandatoryCheckPassed &&
        !this.isOwnershipOptional &&
        (!this.percentageOwnership || this.isNotValidOwnershipField)
      ) {
        return
      }
      const relationshipObj = {
        sgaId: this.sgaId,
        data: {
          entityId: this.selectedEntityId,
          parentId: this.parentId,
          percentageOwnership: this.percentageOwnership,
          ultimateParentId: this.ultimateParentId,
          relationshipEvdIds: null,
          relationshipTypeId: this.selectedRelationshipTypeId.map(
            (item) => item.id
          ),
          relationshipNo: null
        }
      }

      try {
        this.loading = true
        // Evidence upload & mapping
        const evdIdMappingRes = await this.uploadEvidence()
        const mappedEvdIds = this.updateEvidenceIdMapping(evdIdMappingRes)
        relationshipObj.data.relationshipEvdIds =
          mappedEvdIds[this.relationshipEvd.key]?.length > 0
            ? mappedEvdIds[this.relationshipEvd.key]
            : []

        // Check entity status and update it to In Progress, if it's Todo
        await this.checkAndUpdateTodoStatus()
        const addeRelationshipRes = await this.addRelationships(relationshipObj)
        if (addeRelationshipRes === 200) {
          this.snackbarPopup('Relationship data added successfully')
          await this.resetForm()
          await this.getRelationships({
            sgaId: this.sgaId
          })
        } else {
          throw new Error()
        }
      } catch (error) {
        this.snackbarPopup('Relationship data not added')
      } finally {
        this.setValue = false
        this.loading = false
      }
    },

    async resetForm() {
      this.submitted = false
      this.setValue = true
      this.enrichmentObj = null
      this.selectedSgaID = null
      this.selectedEntityId = null
      this.entityName = null
      this.percentageOwnership = null
      this.selectedRelationshipTypeId = []
      this.parentId = null
      this.ultimateParentId = null
      this.resetEvidenceStore()
      this.relationshipEvd.errorMessage = false
      this.isOwnershipOptional = false
    },

    isMandatoryCheckPassed() {
      if (this.submitted && this.controlValidations()) {
        // Reset validation error if user made changes in form
        this.controlValidationError = null
      }
      if (!this.enrichmentObj) {
        return false
      }
      if (
        this.selectedRelationshipTypeId &&
        this.selectedRelationshipTypeId.length > 0
      ) {
        const hasControl = this.selectedRelationshipTypeId.some(
          (item) => item.name === 'Control'
        )
        const hasSignificantInfluence = this.selectedRelationshipTypeId.some(
          (item) => item.name === 'Significant Influence'
        )
        return hasControl || hasSignificantInfluence
      } else {
        return false
      }
    },
    snackbarPopup(message) {
      this.snackbarPayload.message = message
      Snackbar(this.snackbarPayload)
    },
    async deleteRelationshipById(relationshipId) {
      this.confirmationDialogData.visible = true
      this.confirmationDialogData.title = 'Delete Confirmation'
      this.confirmationDialogData.text =
        'Are you sure you want to delete this relationship?'
      this.confirmationDialogData.triggerFunction = async () => {
        this.loading = true
        try {
          // Check entity status and update it to In Progress, if it's Todo
          await this.checkAndUpdateTodoStatus()
          const deleteRelationshipRes = await this.deleteRelationships(
            relationshipId
          )
          if (deleteRelationshipRes === 200) {
            this.snackbarPopup('Relationship data deleted successfully')
            await this.getRelationships({
              sgaId: this.sgaId
            })
          } else {
            throw new Error()
          }
        } catch (error) {
          this.snackbarPopup('Relationship data deletion process failed')
        } finally {
          this.loading = false
        }
      }
    },
    closeDialog() {
      this.confirmationDialogData = {
        ...this.confirmationDialogData,
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    },
    closeEntityAddDialog() {
      this.showAddEntityAddDialog = false
    },
    toggleEvidenceForm({ inputName, isViewEvidence, evidenceIds }) {
      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },
    toggleEvidence(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)
      this.toggleEvidenceForm({ inputName, isViewEvidence, evidenceIds })
    },
    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },
    updateEvidenceMapping(inputName, evdId) {
      this.updateEvidenceIdList(inputName)
      this.$set(this.inputNameMapping, inputName, evdId)
    },
    isEvidenceBtnDisabled() {
      return !this.isEvdFieldFilled
    },
    updateEvidenceIdMapping(evidenceIdMappingResponse) {
      const evidenceIdMapping = {}
      if (
        evidenceIdMappingResponse &&
        Array.isArray(evidenceIdMappingResponse)
      ) {
        evidenceIdMappingResponse.forEach((item) => {
          if (item.evidenceIds && item.evidenceIds.length > 0) {
            const evidenceIds = item.evidenceIds.map((evd) => evd.id)
            evidenceIdMapping[item.attributeName] = evidenceIds
          }
        })
      }
      return evidenceIdMapping
    },
    isValidEvidence() {
      const isRelationshipEvd =
        this.getEvidenceFormStatus(this.relationshipEvd.key) ||
        this.evidenceIdList.includes(this.relationshipEvd.key)
      this.relationshipEvd.errorMessage = !isRelationshipEvd
      return isRelationshipEvd
    },

    controlValidations() {
      // Process if control type is selected
      if (!this.isControlSelected) {
        return true
      }

      // If control is selected then can not add
      if (this.isControlAlreadyAdded) {
        this.controlValidationError =
          'Control relationships type is already added for the selected entity'
        return false
      }

      if (this.sgaId !== this.selectedSgaID) {
        this.controlValidationError =
          'For Control relationship selected Entity must be same as enrichment Entity'
        return false
      }

      this.controlValidationError = null
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.relationships-section {
  margin-top: 1rem;
  position: relative;
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .required-error {
    color: red;
    position: relative;
    font-size: 12px;
    margin-top: 0.3rem;
  }
  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }
  .mandatory-note {
    p {
      font-size: 12px;
      font-weight: normal;
      font-family: Quicksand;
      letter-spacing: 0.14px;
    }
    .mandatory {
      color: red;
      font-size: 17px;
    }
  }

  .workflow-container {
    width: 100%;
    margin-top: 25px;
  }
  .relationship-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0rem 2rem;
    width: 100%;

    .column-view {
      width: 28%;
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .search-field {
        width: 100%;
      }

      ::v-deep .data-source-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .top-label {
          text-align: left;
          font-size: 12px;
          font-family: Quicksand;
          font-weight: 600;
          color: #7d7d7d;
          margin-bottom: 5px;
          line-height: inherit;

          opacity: 1;
        }
        .dropdown-wrap {
          .data-source-header {
            background-color: #ffffff;
            border: 1px solid #dbdbdb;
            span {
              width: 100%;
            }
            .button-container {
              .ellipsis {
                color: #1e1e1e;
                font-size: 13px;
                line-height: 20px;
                font-family: Quicksand;
                font-weight: 600;
                font-style: normal;
                width: 95%;
              }
              .placeholder-color {
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                color: #cccccc;
                opacity: 1;
              }
              svg path {
                stroke-width: 1;
                stroke: #1e1e1e;
              }
            }
          }

          .data-source-abs {
            background: #ffffff 0% 0% no-repeat padding-box;
            color: #000000;

            .custom-checkbox {
              p {
                color: #000000;
                font-size: 13px;
              }
            }
            .custom-checkbox:hover {
              background: rgba(196, 200, 248, 0.3) 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }

    .checkbox-container {
      width: 10%;
      height: 23px;

      label {
        margin-bottom: 5px;
        text-align: left;
        font-size: 12px;
        font-family: Quicksand;
        font-weight: 600;
        color: #7d7d7d;
        line-height: inherit;
        opacity: 1;
      }

      input[type='checkbox'] {
        border: 1px solid #e02727;
        height: 16px;
        width: 16px;
        margin-top: 2px;
        margin-right: 2px;
      }
    }

    .evidence-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 0rem 2rem;
      width: 27%;
      min-width: 250px;
      align-items: flex-end;
    }
    .ownership-container {
      display: flex;
      flex-direction: row;
      width: 20%;
    }
    .add-evidence {
      width: 40%;
      display: flex;
      /* margin-top: 1.25rem; */
      flex-direction: column;
      justify-content: flex-end;
    }
    .owenership {
      width: 50%;
    }

    .add-relation-button {
      width: 40%;
      display: flex;
      /* margin-top: 1.25rem; */
      flex-direction: column;
      justify-content: flex-end;

      .button {
        background: #00218a;
        opacity: 1;
        text-align: left;
        font-size: 13px;
        height: 36px;
        letter-spacing: 0.18px;
        color: #cccccc;
        opacity: 0.7;
      }
      .active-button {
        background: #00218a;
        opacity: 1;
        text-align: left;
        font-size: 13px;
        letter-spacing: 0.18px;
        color: #cccccc;
        opacity: 1;
      }
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .relationships-section {
    .section-title {
      font-size: 21px;
    }
    .mandatory-note {
      p {
        font-size: 12px;
      }
      .mandatory {
        font-size: 17px;
      }
    }
    .workflow-container {
      margin-top: 35px;
    }
    .relationship-form {
      .column-view {
        ::v-deep .data-source-container {
          .top-label {
            font-size: 14px;
          }

          .dropdown-wrap {
            .data-source-abs {
              .custom-checkbox {
                p {
                  font-size: 14px;
                }
              }
            }

            .data-source-header {
              .button-container {
                .ellipsis {
                  font-size: 14px;
                }
              }
            }
          }
        }

        ::v-deep .form-group {
          width: 100%;

          p {
            font-size: 14px;
          }

          .input {
            font-size: 14px;
          }
        }
      }

      .checkbox-container {
        label {
          font-size: 14px;
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .relationships-section {
    .section-title {
      font-size: 21px;
    }
    .mandatory-note {
      p {
        font-size: 12px;
      }
      .mandatory {
        font-size: 17px;
      }
    }
    .workflow-container {
      margin-top: 35px;
    }

    .relationship-form {
      .column-view {
        ::v-deep .data-source-container {
          .top-label {
            font-size: 16px;
          }

          .dropdown-wrap {
            .data-source-abs {
              .custom-checkbox {
                p {
                  font-size: 16px;
                }
              }
            }

            .data-source-header {
              .button-container {
                .ellipsis {
                  font-size: 16px;
                }
              }
            }
          }

          .checkbox-container {
            label {
              font-size: 16px;
            }
          }
        }

        ::v-deep .form-group {
          width: 100%;

          p {
            font-size: 16px;
          }

          .input {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
