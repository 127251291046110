<template>
  <div class="relationship-hierarchy-container">
    <Dialog v-if="visible" :title="title" @close="closeDialog">
      <p class="relationshi-title">
        Please complete/submit all parent hiearchy before submitting current
        entity.
      </p>
      <div class="relationship-hierarchy">
        <RelationshipHierarchy
          @backToEnrichForm="goToForm"
        ></RelationshipHierarchy>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import RelationshipHierarchy from '@/views/DataManagement/RelationshipHierarchy.vue'

import { Button } from '@progress/kendo-vue-buttons'

export default {
  name: 'relationshipHierarchyDialog',
  components: {
    Dialog,
    DialogActionsBar,
    KButton: Button,
    RelationshipHierarchy
  },
  props: {
    // props: ['visible', 'closeDialog', 'triggerFunction', 'title', 'bodyText']
    // visible : Expecting a boolean value
    visible: {
      type: Boolean,
      required: true
    },
    backToEnrichForm: {
      type: Function
    },
    // closeDialog: A function that sets the value of visible to false
    closeDialog: {
      type: Function,
      required: true
    },
    // triggerFunction: A function that should be invoked when click on yes or confirm.
    triggerFunction: {
      type: Function,
      required: true
    },
    // title: The title of the dialog.
    title: {
      type: String,
      default: 'Please confirm' // Default title value
    },
    // bodyText: The content of the dialog / message that will be shown to the user.
    bodyText: {
      type: String,
      default: 'Are you sure you want to continue?' // Default body text value
    },
    // cancelbuttonText: Text for the cancel button / message that will be shown to the user.
    cancelButtonText: {
      type: String,
      default: 'No' // Default body text value
    },
    // confrimButtonText: Text for the confirm button / message that will be shown to the user.
    confirmButtonText: {
      type: String,
      default: 'Yes' // Default body text value
    },
    // type: Type of Dialog
    type: {
      type: String,
      default: 'confirm'
    }
  },

  methods: {
    handleConfirmation() {
      this.triggerFunction()
      this.closeDialog()
    },
    goToForm(obj) {
      this.$emit('backToEnrichForm', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.action-button {
  color: #424242;
  font-size: 1em;
  font-weight: 600;
}

.relationshi-title {
  margin-bottom: 1rem;
  font-size: 1em;
  font-weight: 600;
}

.action-bar {
  // Common CSS for buttons
  .confirm-button {
    background-color: #00218a;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
  }
  .confirm-button:hover {
    background-color: #00218a;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    opacity: 0.9;
  }

  .cancel-button {
    background-color: #cccccc;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;
  }
  .cancel-button:hover {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    opacity: 0.9;
  }
}

/* Base styles for the dialog */
.dialog-warning {
  // Specific CSS for Buttons
  .confirm-button {
    margin: 0 25% 0 25%;
  }
}
::v-deep .k-dialog-wrapper {
  z-index: 1000;
}
::v-deep .k-dialog-wrapper .k-dialog {
  position: relative;
  width: 90%;
}

.popup-msg {
  margin: 25px;
  max-height: 400px;
  text-align: center;
}
</style>
