<template>
  <div class="add-entity-dialog">
    <Dialog
      v-if="visible"
      @close="closeEntityDialog"
      :width="dialogProps.width"
      :height="dialogProps.height"
    >
      <div class="dialog-body">
        <div class="dialog-container">
          <!-- Legal Entity Name -->
          <div class="row">
            <input-vue
              label="Legal Entity Name"
              :value="legalEntityName"
              placeholder="Enter Legal Entity Name"
              :required="true"
              @onChangeName="updateLegalEntityName"
              :min="1"
              :max="500"
              :isAsciiEnglishCharactersOnly="true"
              class="form-input-field form-input-legal-entity-name"
              :submitted="submitted"
            />
          </div>
          <div class="row">
            <div class="row-column">
              <!-- Country of Domicile -->
              <SearchableSelect
                :value="countryDom"
                label="Country of Domicile"
                :required="true"
                :placeholder="'Select Country of Domicile'"
                value_field="value"
                text_field="text"
                class="form-input-field searchable-select"
                @onChangeName="updateCountryDom"
                :isMandatory="true"
                :submitted="submitted"
                :mandatorySubmit="submitted"
                :isLoading="isCountryListLoading"
                :data="countryList"
              />
              <!-- Country of Registry -->
              <SearchableSelect
                :value="countryReg"
                label="Country of Registry"
                :required="false"
                :placeholder="'Select Country of Registry'"
                value_field="value"
                text_field="text"
                :isMandatory="false"
                class="form-input-field searchable-select"
                @onChangeName="updateCountryReg"
                :submitted="submitted"
                :isLoading="isCountryListLoading"
                :data="countryList"
              />
            </div>
          </div>

          <div class="row">
            <div class="row-column">
              <!-- Worktype -->
              <SearchableSelect
                :value="selectedWorkType"
                label="Work Type"
                :required="true"
                :placeholder="'Select Work Type'"
                value_field="value"
                text_field="text"
                class="form-input-field searchable-select"
                @onChangeName="updateWorkType"
                :isMandatory="true"
                :submitted="submitted"
                :mandatorySubmit="submitted"
                :data="workTypes"
              />

              <!-- Anacredit Applicability -->
              <SearchableSelect
                :value="selectedAnaCreditApplicability"
                label="AnaCredit Applicability"
                :required="true"
                :placeholder="'Select AnaCredit Applicability'"
                value_field="value"
                text_field="text"
                class="form-input-field searchable-select"
                @onChangeName="updateAnaCreditApplicability"
                :isMandatory="true"
                :submitted="submitted"
                :mandatorySubmit="submitted"
                :data="anaCreditApplicabilities"
              />
            </div>
          </div>

          <div class="row">
            <div class="row-column">
              <!-- Priority -->
              <SearchableSelect
                v-show="isPriorityRequired"
                :value="selectedPriority"
                label="Priority"
                :disabled="!isPriorityRequired"
                :required="isPriorityRequired"
                :placeholder="'Select Priority'"
                :customErrorMessage="'Priority is required for New Onboarding'"
                value_field="value"
                text_field="text"
                class="form-input-field searchable-select"
                :class="[!isPriorityRequired ? 'form-control-disabled' : '']"
                @onChangeName="updatePriority"
                :isMandatory="isPriorityRequired"
                :submitted="submitted"
                :mandatorySubmit="submitted"
                :data="priorities"
              />
              <!-- Corporate Action -->
              <SearchableSelect
                v-show="isCorporateActionRequired"
                :value="selectedCorporateActionReason"
                label="Corporate Action"
                :disabled="!isCorporateActionRequired"
                :required="isCorporateActionRequired"
                :placeholder="'Select Corporate Action'"
                value_field="value"
                text_field="text"
                class="form-input-field searchable-select"
                @onChangeName="updateCorporateAction"
                :isMandatory="isCorporateActionRequired"
                :submitted="submitted"
                :mandatorySubmit="submitted"
                :isLoading="isEntityDeclineReasonsLoading"
                :data="caDeclinedReasons"
              />

              <DMPSearchSelect
                label="Link Request ID"
                field="linkRequestId"
                :data="linkRequestData"
                :isButtonRequired="true"
                @onChange="onChangeSgaIdOrEntityId"
                @getEntitySGADetails="getEntitySGADetails"
                :submitted="submitted"
                :showAddEntityButton="false"
                :isMandatory="false"
                :setValue="setValue"
                :notFoundMessage="'Entity Not Found'"
                :isLoading="isLoadingLinkRequestId"
              />
            </div>
          </div>
          <div class="row registered-address-row">
            <input-vue
              type="textarea"
              label="Registered Address"
              :value="bestAddress"
              placeholder="Enter Registered Address"
              :required="false"
              @onChangeName="updateBestAddress"
              :min="1"
              :max="500"
              :newLineValidation="true"
              :hashSpecialCharValidation="true"
              :pipeSpecialCharValidation="true"
              :isAsciiEnglishCharactersOnly="true"
              class="form-input-field text-box-area"
              :submitted="submitted"
            />
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <div class="action-buttons">
          <b-button
            class="action-button cancel-button"
            @click="closeEntityDialog"
            :disabled="isLoading"
          >
            Cancel
          </b-button>
          <b-button
            class="action-button confirm-button"
            @click="handleSave"
            :disabled="isLoading"
            :loading="isLoading"
          >
            Save
          </b-button>
        </div>
      </DialogActionsBar>
    </Dialog>
    <div v-if="visible">
      <ConfirmationDialog
        class="mandatory-popup"
        :visible="dialog.visible"
        :title="dialog.title"
        :bodyText="dialog.text"
        :cancelButtonText="dialog.cancelButtonText"
        :confirmButtonText="dialog.confirmButtonText"
        :closeDialog="closeConfirmationDialog"
        :triggerFunction="dialog.triggerFunction"
      />
    </div>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import DMPSearchSelect from '@/components/DMP/DMPSearchSelect.vue'
import SearchableSelect from '@/components/DMP/SearchableSelect'
import { DECLINED_REASON_TYPES } from '@/constant/data.js'
import { isValidEnglishASCII } from '@/util/util'
import InputVue from '@/views/Admin/components/Input.vue'
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'vuex'
import { workTypeEntities } from '../../constant/data'

const DEFAULT_WORK_TYPES = [
  {
    text: workTypeEntities.CORPORATE_ACTION,
    value: workTypeEntities.CORPORATE_ACTION
  },
  {
    text: workTypeEntities.DATA_ENRICHEMENT,
    value: workTypeEntities.DATA_ENRICHEMENT
  },
  {
    text: workTypeEntities.NEW_ONBOARDING,
    value: workTypeEntities.NEW_ONBOARDING
  },
  {
    text: workTypeEntities.PERIODIC_REVIEW,
    value: workTypeEntities.PERIODIC_REVIEW
  }
]

const PRIORITIES = [
  { text: 'High', value: 'high' },
  { text: 'Medium', value: 'medium' },
  { text: 'Low', value: 'low' }
]

const ANA_CREDIT_APPLICABILITY_OPTIONS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false }
]

export default {
  name: 'AddEntityDialog',
  components: {
    Dialog,
    InputVue,
    SearchableSelect,
    DialogActionsBar,
    DMPSearchSelect,
    ConfirmationDialog
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    closeDialog: {
      type: Function,
      required: true
    },
    isFromPMDashboard: {
      type: Boolean,
      default: false
    },
    isPMRole: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      legalEntityName: '',
      countryDom: null,
      countryReg: null,
      bestAddress: '',
      toMapEntityId: null,
      toMapSgaId: null,
      countryDomList: [],
      submitted: false,
      workTypes: DEFAULT_WORK_TYPES,
      priorities: PRIORITIES,
      anaCreditApplicabilities: ANA_CREDIT_APPLICABILITY_OPTIONS,
      selectedWorkType: null,
      selectedPriority: null,
      selectedAnaCreditApplicability: null,
      selectedCorporateActionReason: null,
      setValue: false,
      dialogProps: {
        width: 1050,
        height: 600
      },
      isInValidAddressChar: false,

      // confirmation popup
      dialog: {
        visible: false,
        title: 'Below hierarchy details will be affected?',
        text: '',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm',
        triggerFunction: () => {}
      },

      isReassigningToNewEntity: false,
      isNewEntityAdded: false,
      relationshipIds: null
    }
  },
  computed: {
    ...mapState('relationship', {
      relationshipLoading: (state) => state.isLoading,
      countryList: (state) => state.countryList,
      isCountryListLoading: (state) => state.isCountryListLoading,
      linkRequestData: (state) => state.linkRequestData,
      isLoadingLinkRequestId: (state) => state.isLoadingLinkRequestId
    }),
    ...mapState('pmDashboard', {
      // Rename 'isLoading' from 'pmDashboard' module
      declinedReasons: (state) => state.declineReasons,
      caDeclinedReasons: (state) => state.caDeclinedReasons,
      pmDashboardLoading: (state) => state.isLoading,
      isEntityDeclineReasonsLoading: (state) =>
        state.isEntityDeclineReasonsLoading
    }),
    isLoading() {
      return this.isFromPMDashboard
        ? this.pmDashboardLoading
        : this.relationshipLoading
    },
    isValidForm() {
      const isValidLegalEntityName =
        this.legalEntityName &&
        this.legalEntityName.length > 0 &&
        this.legalEntityName.length <= 500 &&
        isValidEnglishASCII(this.legalEntityName)
      const isValidCountryofDOM =
        this.countryDom &&
        ![null, undefined].includes(this.countryDom) &&
        this.countryDom.value !== null
      const isValidBestAddress = this.bestAddress
        ? this.bestAddress.length <= 500 &&
          isValidEnglishASCII(this.bestAddress)
        : true
      const isValidWorkType =
        this.selectedWorkType &&
        ![null, undefined].includes(this.selectedWorkType) &&
        this.selectedWorkType.value !== null

      const isValidWorkAnaCreditApplicability =
        this.selectedAnaCreditApplicability &&
        ![null, undefined].includes(this.selectedAnaCreditApplicability) &&
        this.selectedAnaCreditApplicability.value !== null

      const isValidPriorityValue =
        this.selectedPriority &&
        ![null, undefined].includes(this.selectedPriority) &&
        this.selectedPriority.value !== null

      const isValidCorporateActionValue = this.isCorporateActionRequired
        ? this.selectedCorporateActionReason &&
          ![null, undefined].includes(this.selectedCorporateActionReason) &&
          this.selectedCorporateActionReason.value !== null
        : true

      const isValidPriority =
        isValidWorkType &&
        (this.selectedWorkType.value === workTypeEntities.NEW_ONBOARDING
          ? isValidPriorityValue
          : true)

      const isValidCoporateAction =
        isValidWorkType &&
        (this.selectedWorkType.value === workTypeEntities.CORPORATE_ACTION
          ? isValidCorporateActionValue
          : true)

      return (
        isValidLegalEntityName &&
        isValidCountryofDOM &&
        isValidBestAddress &&
        !this.isInValidAddressChar &&
        isValidWorkType &&
        isValidPriority &&
        isValidWorkAnaCreditApplicability &&
        isValidCoporateAction
      )
    },
    isPriorityRequired() {
      return (
        this.selectedWorkType &&
        this.selectedWorkType.value === workTypeEntities.NEW_ONBOARDING
      )
    },
    isCorporateActionRequired() {
      return (
        this.selectedWorkType &&
        this.selectedWorkType.value === workTypeEntities.CORPORATE_ACTION
      )
    }
  },

  watch: {
    isReassigningToNewEntity() {
      if (this.isReassigningToNewEntity) {
        this.closeEntityDialog()
        if (this.isFromPMDashboard) {
          this.setDashboardLoader(true)
          this.getEntityDetailOverView()
          this.getpmDashboardData()
        }
      }
    },
    isNewEntityAdded() {
      if (this.isNewEntityAdded) {
        this.closeEntityDialog()
        if (this.isFromPMDashboard) {
          this.setDashboardLoader(true)
          this.getEntityDetailOverView()
          this.getpmDashboardData()
        }
      }
    },
    visible: {
      handler(newVal) {
        if (newVal) {
          const entityObj = {
            searchQuery: '',
            field: 'linkRequestId'
          }
          this.getDataForEntityDropdown(entityObj)
        }
      },
      immediate: true
    }
  },

  async mounted() {
    this.setDialogSize()
    window.addEventListener('resize', this.setDialogSize)
    await this.getCountryList()
    await this.getDeclinedReasons([
      DECLINED_REASON_TYPES.CA,
      DECLINED_REASON_TYPES.REMAP
    ])
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDialogSize)
  },
  methods: {
    ...mapActions('relationship', [
      'addNewEntity',
      'getCountryList',
      'getDataForEntityDropdown',
      'updateLinkRequestData'
    ]),
    ...mapActions('pmDashboard', [
      'addNewEntityFromPMView',
      'getpmDashboardData',
      'getDeclinedReasons',
      'setDashboardLoader',
      'getHierarchyDetails'
    ]),
    ...mapActions('assignedEntities', ['getEntityDetailOverView']),

    async handleSave() {
      this.submitted = true
      if (!this.isValidForm) {
        return
      }

      const isCorporateAndDuplicateReason =
        this.selectedWorkType?.value === workTypeEntities.CORPORATE_ACTION &&
        this.selectedCorporateActionReason?.text.split('-')[0] === 'C11'
      if (isCorporateAndDuplicateReason) {
        await this.getUserConfirmationForReassigning()
      } else {
        this.isNewEntityAdded = await this.createPayloadAndUpdateData()
      }
    },
    onChangeSgaIdOrEntityId(obj) {
      if (obj) {
        this.toMapEntityId = obj.entityId
        this.toMapSgaId = obj.sgaId
      }
    },
    updateLegalEntityName(legalEntity) {
      this.legalEntityName = legalEntity
    },
    async getEntitySGADetails(searchQuery, field) {
      const params = {
        searchQuery: searchQuery,
        field: field
      }
      if (searchQuery) {
        await this.getDataForEntityDropdown(params)
      }
    },
    updateCountryDom(country) {
      this.countryDom = country
    },
    updateCountryReg(country) {
      this.countryReg = country
    },
    updateBestAddress(address, isInValidAddressChar) {
      this.isInValidAddressChar = isInValidAddressChar
      this.bestAddress = address
    },
    updateWorkType(workType) {
      this.selectedWorkType = workType

      switch (workType?.value) {
        case workTypeEntities.NEW_ONBOARDING:
          this.selectedPriority = null
          break
        case workTypeEntities.CORPORATE_ACTION:
          this.selectedCorporateActionReason = null
          break
        default:
          this.selectedPriority = null
          this.selectedCorporateActionReason = null
          break
      }
    },
    updatePriority(priority) {
      this.selectedPriority = priority
    },
    updateAnaCreditApplicability(value) {
      this.selectedAnaCreditApplicability = value
    },
    updateCorporateAction(value) {
      this.selectedCorporateActionReason = value
    },
    async createPayloadAndUpdateData() {
      const payload = {
        legalEntityName: this.legalEntityName,
        countryDOM:
          this.countryDom && ![null, undefined].includes(this.countryDom)
            ? this.countryDom.value
            : null,
        countryReg:
          this.countryReg && ![null, undefined].includes(this.countryReg)
            ? this.countryReg.value
            : null,
        bestAddress: this.bestAddress,
        workType:
          this.selectedWorkType &&
          ![null, undefined].includes(this.selectedWorkType)
            ? this.selectedWorkType.value
            : null,
        priority:
          this.selectedPriority &&
          ![null, undefined].includes(this.selectedPriority)
            ? this.selectedPriority.value
            : null,
        isAnaCreditApplicable:
          this.selectedAnaCreditApplicability &&
          ![null, undefined].includes(this.selectedAnaCreditApplicability)
            ? this.selectedAnaCreditApplicability.value
            : null,
        corporateActionReasonId:
          this.selectedCorporateActionReason &&
          ![null, undefined].includes(this.selectedCorporateActionReason)
            ? this.selectedCorporateActionReason.value
            : null,
        toMapEntityId: this.toMapEntityId ? this.toMapEntityId : null,
        relationshipIds: this.relationshipIds ? this.relationshipIds : null
      }

      // Choose the function based on `this.isFromPMDashboard`
      const addEntityFunction = this.isFromPMDashboard
        ? this.addNewEntityFromPMView
        : this.addNewEntity

      // Execute the chosen function and check the response status
      const responseStatus = await addEntityFunction.call(this, payload)
      return responseStatus && responseStatus === 200
    },
    async getUserConfirmationForReassigning() {
      let isNewEntityAdded = false
      const relationshipData = await this.getHierarchyDetails({
        sgaIds: [this.toMapSgaId]
      })

      // no heirarchy found
      if (isEmpty(relationshipData)) {
        isNewEntityAdded = await this.createPayloadAndUpdateData()
        this.isReassigningToNewEntity = isNewEntityAdded
        return
      }

      const excludeColumns = [
        'relatioshipId',
        'entityId',
        'parentId',
        'ultimateParentId'
      ]

      let popUpMsg = ''
      let tableHTML = "<table border='1'>"

      // table header
      tableHTML += '<tr>'
      for (const key in relationshipData[0]) {
        if (!excludeColumns.includes(key)) {
          const columnName = key
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/^./, function (str) {
              return str.toUpperCase()
            })

          tableHTML += `<th style='padding:12px'>${columnName}</th>`
        }
      }
      tableHTML += '</tr>'

      // table rows
      for (let i = 0; i < relationshipData.length; i++) {
        tableHTML += '<tr>'
        for (const key in relationshipData[i]) {
          if (!excludeColumns.includes(key)) {
            tableHTML += `<td style='padding:12px'>${relationshipData[i][key]}</td>`
          }
        }
        tableHTML += '</tr>'
      }
      tableHTML += '</table>'

      popUpMsg += `${tableHTML}`

      this.dialog.title = `${this.toMapSgaId} is in these respective hierarchy would you like to replace with \n${this.legalEntityName}?`

      this.dialog.visible = true
      this.dialog.text = `${popUpMsg}`
      this.dialog.triggerFunction = async () => {
        // User confirmed,
        this.relationshipIds = relationshipData.map(
          (relationshipObj) => relationshipObj.relatioshipId
        )
        isNewEntityAdded = await this.createPayloadAndUpdateData()
        this.isReassigningToNewEntity = isNewEntityAdded
      }
    },
    resetEntityDialogDetails() {
      this.legalEntityName = ''
      this.countryDom = null
      this.countryReg = null
      this.bestAddress = ''
      this.submitted = false
      this.selectedPriority = null
      this.selectedWorkType = null
      this.selectedAnaCreditApplicability = null
      this.selectedCorporateActionReason = null
    },
    closeEntityDialog() {
      this.closeDialog()
      this.updateLinkRequestData([])
      this.resetEntityDialogDetails([])
    },
    setDialogSize() {
      const windowWidth = window.innerWidth
      const mediumWidth = 1450
      const smallWidth = 1000

      if (windowWidth > mediumWidth) {
        this.dialogProps.width = 1050
      } else if (windowWidth > mediumWidth) {
        this.dialogProps.width = 1050
      } else if (windowWidth > smallWidth) {
        this.dialogProps.width = 900
      } else {
        this.dialogProps.width =
          (1050 * (windowWidth - smallWidth)) / (mediumWidth - smallWidth) +
          1050
      }
    },

    closeConfirmationDialog() {
      this.dialog.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-body {
  padding: 3.875rem;
  .row {
    margin-bottom: 38px;
  }

  .registered-address-row {
    margin-bottom: 0;
  }

  .row-container {
    display: flex;
    gap: 96px;
    width: 100%;
    .column {
      width: 100%;
      padding: 0;
      .column-row {
        margin-bottom: 38px;
        .priority-info {
          font-style: normal;
          font-variant: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          font-family: Quicksand;
          color: #7d7d7d;
        }
        .form-control-disabled {
          cursor: not-allowed;
        }
      }
      .text-box-area {
        width: 100%;
        height: 100%;
        padding: 0%;
        margin: 0%;
      }
    }
  }

  .row-column {
    display: flex;
    margin-bottom: 38px;
    width: 100%;
    gap: 96px;
    .searchable-select {
      width: 100%;
    }
  }
  ::v-deep .form-input-legal-entity-name {
    P {
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      font-family: Quicksand;
      color: #7d7d7d;
    }
    .input {
      text-align: left;
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      font-family: Quicksand;
      color: #1e1e1e;
      opacity: 1;
      border-color: #cccccc;
    }
    .input::placeholder {
      font-weight: 800;
    }
  }

  ::v-deep .form-input-field {
    P {
      margin-bottom: 10px;
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      font-family: Quicksand;
      color: #7d7d7d;
    }
    .textarea {
      text-align: left;
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      font-family: Quicksand;
      color: #1e1e1e;
      opacity: 1;
      border-color: #cccccc;
    }

    .textarea::placeholder {
      font-weight: 800;
    }
  }

  ::v-deep .combo-box-label {
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    font-family: Quicksand;
    margin-bottom: 10px;
  }
  ::v-deep .k-input-inner::placeholder {
    font-weight: 800;
  }
}
::v-deep .k-dialog-actions {
  border: none;
}
::v-deep .k-window-actions {
  border: none;
}
::v-deep .k-dialog-content {
  overflow: auto;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  height: 5rem;
  padding-bottom: 0.6rem;
  width: 100%;
}
.action-button {
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  min-width: 104px;
  height: 46px;
}
.confirm-button {
  background-color: #535eeb; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */
  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: left;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-left: 1rem;
}
.confirm-button:hover {
  background-color: #00218a; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */

  opacity: 0.9;
}

.cancel-button {
  background-color: #cccccc; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */

  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #444444;
  opacity: 1;
}
.cancel-button:hover {
  background-color: #cccccc; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */
  opacity: 0.9;
}

@media (max-width: 865px) {
  .dialog-body {
    .row-column {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 38px;
    }
    .row-container {
      flex-direction: column;
      gap: 0;
      margin-bottom: 38px;
    }
    ::v-deep .form-input-legal-entity-name {
      P {
        font-size: 14px;
      }
      .input {
        font-size: 14px;
      }
    }

    ::v-deep .form-input-field {
      P {
        font-size: 14px;
      }
      .textarea {
        font-size: 14px;
      }
    }

    ::v-deep .combo-box-label {
      font-size: 14px;
      margin-bottom: 6px;
    }
  }

  .action-button {
    font-size: 14px;
  }
  .confirm-button {
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }

  .cancel-button {
    text-align: center;
    font-size: 14px;
    line-height: 23px;
  }
  ::v-deep .k-dialog-content {
    overflow: auto;
  }
}
@media (min-width: 1700px) {
  .dialog-body {
    ::v-deep .form-input-legal-entity-name {
      P {
        font-size: 18px;
      }
      .input {
        font-size: 18px;
      }
    }

    ::v-deep .form-input-field {
      P {
        font-size: 18px;
      }
      .textarea {
        font-size: 18px;
      }
    }

    ::v-deep .combo-box-label {
      font-size: 18px;
      margin-bottom: 6px;
    }
  }

  .action-button {
    font-size: 18px;
  }
  .confirm-button {
    text-align: left;
    font-size: 18px;
    line-height: 23px;
  }

  .cancel-button {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
  }
}
</style>

<style lang="scss">
.eadd-entity-dialog {
  .mandatory-popup {
    .k-dialog-wrapper {
      .k-window-content {
        .popup-msg {
          text-align: left;
        }
      }
    }
  }
}
</style>
