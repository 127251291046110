<template>
  <div>
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <div class="example-config">
      <button
        @click="expandAll"
        class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
      >
        Expand all
      </button>
      <button
        @click="collapseAll"
        class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
      >
        Collapse all
      </button>
    </div>
    <TreeList
      :style="{ maxHeight: '510px', overflow: 'auto', width: '100%' }"
      :table-props="{ style: { width: '100%' } }"
      :navigatable="true"
      :dataItems="processedData"
      :expandField="expandField"
      :subItemsField="subItemsField"
      @expandchange="onExpandChange"
      :columns="columns"
    >
      <template v-slot:myCellTemplate="{ props }">
        <td>
          <span>
            <a @click="goToEnrichmentForm('sgaId', props.dataItem)">
              <p class="highlighted-sgaid">
                {{ props.value }}
              </p>
            </a>
          </span>
        </td>
      </template>

      <template v-slot:immediateParentTemplate="{ props }">
        <td>
          <span>
            <a
              @click="
                goToEnrichmentForm('immediateParentSgaId', props.dataItem)
              "
            >
              <p
                :class="
                  props.dataItem.isImmediateParentBrokenHiearchy
                    ? 'highlighted-broken-sgaid'
                    : 'highlighted-sgaid'
                "
              >
                {{ props.value }}
              </p>
            </a>
          </span>
        </td>
      </template>

      <template v-slot:statusTemplate="{ props }">
        <td>
          <span>
            <p>
              {{ getStatus(props.value) }}
            </p>
          </span>
        </td>
      </template>
    </TreeList>
  </div>
</template>

<script>
import { TreeList, mapTree, extendDataItem } from '@progress/kendo-vue-treelist'
import { mapActions, mapGetters, mapState } from 'vuex'
import Snackbar from '@/components/Snackbar'
import { userRoles } from '../../util/permissions.utils'
import Vue from 'vue'
import { entityStatus } from '@/constant/data.js'

export default {
  components: {
    TreeList
  },

  props: {
    backToForm: {
      type: Function
    }
  },

  data() {
    return {
      expanded: [], // Start with no items expanded, you can adjust this as needed
      subItemsField: 'items', // Change this to match the data field in your data
      expandField: 'expanded', // Ensure this field is used correctly
      snackbarPayload: {
        // Set default payload
        message: null,
        type: 'is-warning',
        duration: 3000
      },
      columns: [
        {
          field: 'legalEntityName',
          title: 'Legal Entity Name',
          width: '40%',
          expandable: true
        },
        {
          field: 'sgaId',
          title: 'SGA ID',
          width: '15%',
          cell: 'myCellTemplate'
        },
        {
          field: 'immediateParentSgaId',
          title: 'Immediate Parent',
          width: '12%',
          cell: 'immediateParentTemplate'
        },
        {
          field: 'status',
          title: 'Status',
          width: '13%',
          cell: 'statusTemplate'
        },
        {
          field: 'relationship',
          title: 'Relationship Type',
          width: '10%'
        },
        {
          field: 'ownership',
          title: 'Ownership %',
          width: '10%'
        }
      ],
      userRoles,
      loading: this.isLoading
    }
  },
  computed: {
    ...mapState('relationship', ['relationshipHierarchy', 'isLoading']),
    ...mapState('user', ['userDetails']),
    ...mapState('pmDashboard', ['teamMemberList']),

    processedData() {
      return mapTree(this.relationshipHierarchy, this.subItemsField, (item) =>
        extendDataItem(item, this.subItemsField, {
          [this.expandField]: this.expanded.includes(item.sgaId)
        })
      )
    }
  },

  watch: {
    isLoading(newVal) {
      this.loading = newVal
    }
  },

  methods: {
    ...mapActions('relationship', ['updateHierarchyView']),
    expandAll() {
      const allIds = this.getAllIds(this.processedData)
      this.expanded = [...new Set(allIds)]
    },
    getAllIds(items) {
      let ids = []
      items.forEach((item) => {
        ids.push(item.sgaId)
        if (item[this.subItemsField]) {
          ids = ids.concat(this.getAllIds(item[this.subItemsField]))
        }
      })
      return ids
    },

    collapseAll() {
      this.expanded = []
    },
    getStatus(value) {
      if (value === entityStatus.SUBMIT || value === entityStatus.STAGING) {
        return 'Submitted'
      } else {
        return value
      }
    },

    onExpandChange(event) {
      this.expanded = event.value
        ? this.expanded.filter((id) => id !== event.dataItem.sgaId)
        : [...this.expanded, event.dataItem.sgaId]
    },
    async goToEnrichmentForm(field, dataItem) {
      try {
        this.loading = true
        const { userId, role, roleName } = this.userDetails
        let { assignedTo, assignmentId, sgaId, workType, assignedToUsername } =
          dataItem

        // Replace current sgaId with immediate parent sgaId
        if (field === 'immediateParentSgaId') {
          const response = await Vue.prototype.$http.post(
            `/dmp/entity-detail?sgaId=${dataItem.immediateParentSgaId}`,
            { roleId: role }
          )

          if (response.data.status === 200) {
            const parentEntityDetails = response.data.data.entityDetails[0]
            const parentEntityWorktype = parentEntityDetails?.workType
            assignedTo = parentEntityDetails.assignedTo
            sgaId = dataItem.immediateParentSgaId
            assignmentId = parentEntityDetails.assignmentId
            workType =
              parentEntityWorktype && parentEntityWorktype.length > 0
                ? parentEntityWorktype[0]
                : 'Data Enrichment'
            assignedToUsername = parentEntityDetails?.assignedToUsername
          }
        }

        // Entity access criterias

        // Check if role is PM Manger and Entity is assigned to team member
        const checkPMManagerAccess =
          roleName === this.userRoles.PM_MANAGER &&
          this.teamMemberList &&
          this.teamMemberList
            .map((member) => member.userId?.toString())
            .includes(assignedTo?.toString())

        // Check roles access
        const checkAccessPermission =
          assignedTo &&
          (userId.toString() === assignedTo.toString() || checkPMManagerAccess)

        if (checkAccessPermission) {
          const obj = {}
          obj.assignmentId = assignmentId
          obj.sgaId = sgaId
          obj.workType = workType
          this.$emit('backToEnrichForm', obj)
        } else {
          Snackbar({
            message:
              (assignedTo
                ? `Entity ${sgaId} is assigned to ${assignedToUsername}`
                : `Entity ${sgaId} is not assigned to any user`) +
              `, please connect with your manager`,
            type: 'is-warning',
            indefinite: true
          })
        }
      } catch (error) {
        Snackbar({
          message: 'Something went wrong. Please try again',
          type: 'is-danger'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
::v-deep .snackbar {
  z-index: 1000000; /* Set this higher than your popup's z-index */
}
.highlighted-broken-sgaid {
  text-decoration: underline;
  color: red;
}
.highlighted-sgaid {
  text-decoration: underline;
}
.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  background: rgba(0, 0, 0, 0.4);
  z-index: 9;
  .inava-loader {
    position: relative;
    display: flex;
    margin: auto;
    border-radius: 50%;
    border: none;
    z-index: 900;
    width: 70px;
    height: 70px;
    background-image: '@/assets/Inava-logo-GIF.gif';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
