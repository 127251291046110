<template>
  <div class="entity-details">
    <div class="menu-stepper-container">
      <MenuStepper
        :value="activeStepIndex"
        @change="handleChange"
        :items="stepList"
      ></MenuStepper>
    </div>
    <div class="entity-details-main">
      <!-- Entity Details Main Area -->
      <div class="entity-details-main-container">
        <QuickReferenceDetails :quickReferenceData="quickRefDetails" />
        <CollapsibleBVDForm
          v-if="shouldShowCollapsibleForm"
          :title="getFormTitle"
          :formName="formName"
          :sgaId="sgaId"
          :formData="formData"
          @save="saveChanges"
          :isAnaCreditApplicable="isAnaCreditApplicable"
        />
        <component
          :is="formName"
          @toggleEvidenceForm="toggleEvidenceForm"
          :updateStepper="handleChange"
          :isAnaCreditApplicable="isAnaCreditApplicable"
          :countryCode="quickRefDetails.countryId"
          ref="enrichmentFormRef"
        ></component>
      </div>
    </div>
    <EvidenceForm
      :key="evidenceId"
      :evidenceId="evidenceId"
      :attributeName="evidenceId"
      :isViewEvidence="isViewEvidence"
      :open="open"
      :formName="formName"
      @closeEvidenceForm="closeEvidenceForm"
    ></EvidenceForm>
  </div>
</template>

<script>
import EvidenceForm from '@/components/DMP/EvidenceForm.vue'
import MenuStepper from '@/components/DMP/MenuStepper.vue'
import QuickReferenceDetails from '@/components/DMP/QuickReferenceDetails.vue'
import { activeStepIndexName, entitiesStepperList } from '@/constant/data.js'
import AdderessForm from '@/views/DataManagement/AddressDetails/AddressForm.vue'
import ADetailsForm from '@/views/DataManagement/ADetails/ADetailsForm.vue'
import AnaCreditDetailsForm from '@/views/DataManagement/AnaCreditDetails/AnaCreditDetailsForm.vue'
import BasicDetailsForm from '@/views/DataManagement/BasicDetails/BasicDetailsForm.vue'
import IdentifiersForm from '@/views/DataManagement/Identifiers/IdentifiersForm.vue'
import IndustryForm from '@/views/DataManagement/IndustryDetails/IndustryForm.vue'
import RelationshipsForm from '@/views/DataManagement/Relationships/RelationshipsForm.vue'
import Vue from 'vue'
import CollapsibleBVDForm from './CollapsibleBVDForm.vue'

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'EntityDetails',
  components: {
    AnaCreditDetailsForm,
    MenuStepper,
    BasicDetailsForm,
    AdderessForm,
    EvidenceForm,
    IndustryForm,
    IdentifiersForm,
    ADetailsForm,
    RelationshipsForm,
    QuickReferenceDetails,
    CollapsibleBVDForm
  },

  data() {
    return {
      sgaId: '',
      isEnabled: false,
      stepList: entitiesStepperList,
      evidenceId: null,
      open: false,
      activeStepIndexName: activeStepIndexName,
      activeStepIndex: activeStepIndexName.basicDetails,
      isViewEvidence: false,
      formName: 'BasicDetailsForm', // default
      formData: {},
      quickRefDetails: {
        requestId: {
          label: 'Request ID',
          value: ''
        },
        sgaId: {
          label: 'SGA ID',
          value: ''
        },
        legalEntityName: {
          label: 'Legal Entity Name',
          value: ''
        },
        taxIdType: {
          label: 'Tax ID Type',
          value: ''
        },
        taxId: {
          label: 'Tax ID Value',
          value: ''
        },
        lei: {
          label: 'LEI',
          value: ''
        },
        anacreditApplicability: {
          label: 'AnaCredit Applicability',
          value: ''
        },
        anacreditApplicabilityflag: {
          label: 'AnaCredit Applicability Flag',
          value: ''
        },
        entityAnaCreditApplicabilityflag: {
          label: 'Entity AnaCredit Applicability Flag',
          value: ''
        },
        domicileAddress: {
          label: 'Address',
          value: ''
        },
        domicileCityName: {
          label: 'City',
          value: ''
        },
        domicileStateProvinceCode: {
          label: 'State/Province Code',
          value: ''
        },
        domicileCountryCodeIso: {
          label: 'Country/ISO',
          value: ''
        },
        domicilePostalCode: {
          label: 'Postal Code',
          value: ''
        },
        registrationAddress: {
          label: 'Address',
          value: ''
        },
        registrationCityName: {
          label: 'City',
          value: ''
        },
        registrationStateProvinceCode: {
          label: 'State/Province Code',
          value: ''
        },
        registrationCountryCodeIso: {
          label: 'Country/ISO',
          value: ''
        },
        registrationPostalCode: {
          label: 'Postal Code',
          value: ''
        },
        taxTypeId: {
          label: 'Type of Tax ID',
          value: ''
        },
        immediateParentRequestId: {
          label: 'Request ID',
          value: ''
        },
        immediateParentPartyName: {
          label: 'Party Name',
          value: ''
        },
        immediateParentPartyCountryOfDomicile: {
          label: 'Country of Domicile',
          value: ''
        },
        immediateParentPartyCountryOfRegistration: {
          label: 'Country of Registration',
          value: ''
        },
        ultimateParentRequestId: {
          label: 'Request ID',
          value: ''
        },
        ultimateParentPartyName: {
          label: 'Party Name',
          value: ''
        },
        ultimateParentPartyCountryOfDomicile: {
          label: 'Country of Domicile',
          value: ''
        },
        ultimateParentPartyCountryOfRegistration: {
          label: 'Country of Registration',
          value: ''
        },
        isAutoEnrolled: {
          label: '',
          value: null
        },
        priority: {
          label: 'Priority',
          value: ''
        },
        unsubscribe: {
          label: 'Unsubscribe',
          value: ''
        }
      }
    }
  },

  computed: {
    ...mapState('evidence', ['selectedUploadFiles']),
    ...mapGetters('evidence', ['isEvidencePresent']),
    ...mapGetters('dmp', ['getEntityDetails', 'getStepperForm']),
    ...mapState('anaCredit', ['isAnaCreditApplicable']),
    shouldShowCollapsibleForm() {
      return this.formName !== 'ADetailsForm'
    },
    getFormTitle() {
      return this.formName === 'RelationshipsForm'
        ? 'BVD Reference Value - Display'
        : 'BVD Reference Value'
    }
  },

  watch: {
    selectedUploadFiles(newSelectedUploadFiles, oldSelectedUploadFiles) {
      if (newSelectedUploadFiles.length === 0) {
        this.isViewEvidence = false
      }
    }
  },
  async mounted() {
    const { sgaId } = this.getEntityDetails
    this.sgaId = sgaId
    // Reset Forms Store
    this.resetFormsDetails()
    this.getAnaCreditApplicability(sgaId)
    const stepperIndex = this.getStepperForm
    if (stepperIndex) this.handleChange(stepperIndex, false)
    await this.getQuickReferenceDetails(this.sgaId)
    // Reset stepper
    this.updateStepperForm(null)
  },
  methods: {
    ...mapActions('evidence', [
      'getEvidenceById',
      'resetEvidenceForm',
      'viewEvidence'
    ]),
    ...mapActions('dmp', ['updateStepperForm', 'resetFormsDetails']),
    ...mapActions('anaCredit', ['getAnaCreditApplicability']),

    async handleChange(index, fetchQuickRef = true, fromChild = false) {
      this.activeStepIndex = index
      // If stepperIndex is defined on mount then skip repeat fetch
      const { sgaId } = this.getEntityDetails
      this.sgaId = sgaId
      if (fetchQuickRef) await this.getQuickReferenceDetails(sgaId)
      if (fromChild) {
        this.$refs.enrichmentFormRef.getFormDetails()
      }
      switch (index) {
        case 0:
          this.formName = 'BasicDetailsForm'
          break
        case 1:
          this.formName = 'AdderessForm'
          break
        case 2:
          this.formName = 'IndustryForm'
          break
        case 3:
          this.formName = 'IdentifiersForm'
          break
        case 4:
          this.formName = 'AnaCreditDetailsForm'
          break
        case 5:
          this.formName = 'ADetailsForm'
          break
        case 6:
          this.formName = 'RelationshipsForm'
          break
      }
    },
    saveChanges(updatedData) {
      // TODO: not required
      if (
        this.$refs[this.formName] &&
        typeof this.$refs[this.formName].saveChanges === 'function'
      ) {
        this.$refs[this.formName].saveChanges(updatedData)
      }
    },

    async toggleEvidenceForm({ inputName, isViewEvidence, evidenceIds = [] }) {
      let isOpen = false
      if (
        isViewEvidence &&
        evidenceIds.length &&
        !this.isEvidencePresent(inputName)
      ) {
        isOpen = await this.viewEvidence({ inputName, evidenceIds })
      } else {
        isOpen = true
      }
      this.isViewEvidence = isViewEvidence
      this.evidenceId = inputName
      this.open = isOpen
      this.resetEvidenceForm() // clear previous evidence form data
      if (this.open && this.evidenceId) {
        // load evidence form data as per evidence id if present
        this.getEvidenceById(this.evidenceId)
      }
    },

    closeEvidenceForm(isClose) {
      this.open = isClose
    },
    async getQuickReferenceDetails(sgaId) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/quick-reference-details?sgaId=${sgaId}`
        )
        if (response.data.status === 200 && response.data.data) {
          this.populateQuickRefDetails(response.data.data)
        }
      } catch (error) {
        throw error
      }
    },
    formatAddress(line1, line2, line3) {
      return [line1, line2, line3].filter((line) => line).join(', ')
    },

    populateQuickRefDetails(details) {
      this.quickRefDetails.requestId.value = details.requestId
      this.quickRefDetails.sgaId = details.sgaId
      // this.quickRefDetails.request = details.request
      this.quickRefDetails.legalEntityName.value = details.legalEntityName
      this.quickRefDetails.taxIdType.value = details.taxIdType
      this.quickRefDetails.taxId.value = details.taxId || ''
      this.quickRefDetails.lei.value = details.lei
      this.quickRefDetails.domicileAddress.value = this.formatAddress(
        details.domicileAddressLine1,
        details.domicileAddressLine2,
        details.domicileAddressLine3
      )
      this.quickRefDetails.domicileCityName.value =
        details.domicileCityName || ''
      this.quickRefDetails.domicileStateProvinceCode.value =
        details.domicileStateProvinceCode || ''
      this.quickRefDetails.domicileCountryCodeIso.value =
        details.domicileCountryCodeIso || ''
      this.quickRefDetails.domicilePostalCode.value =
        details.domicilePostalCode || ''
      this.quickRefDetails.registrationAddress.value = this.formatAddress(
        details.registrationAddressLine1,
        details.registrationAddressLine2,
        details.registrationAddressLine3
      )

      this.quickRefDetails.registrationCityName.value =
        details.registrationCityName || ''
      this.quickRefDetails.registrationStateProvinceCode.value =
        details.registrationStateProvinceCode || ''
      this.quickRefDetails.registrationCountryCodeIso.value =
        details.registrationCountryCodeIso || ''
      this.quickRefDetails.registrationPostalCode.value =
        details.registrationPostalCode || ''
      this.quickRefDetails.immediateParentRequestId.value =
        details.immediateParentRequestId || ''
      this.quickRefDetails.immediateParentPartyName.value =
        details.immediateParentPartyName || ''
      this.quickRefDetails.immediateParentPartyCountryOfDomicile.value =
        details.immediateParentPartyCountryOfDomicile || ''
      this.quickRefDetails.immediateParentPartyCountryOfRegistration.value =
        details.immediateParentPartyCountryOfRegistration || ''
      this.quickRefDetails.ultimateParentRequestId.value =
        details.ultimateParentRequestId || ''
      this.quickRefDetails.ultimateParentPartyName.value =
        details.ultimateParentPartyName || ''
      this.quickRefDetails.ultimateParentPartyCountryOfDomicile.value =
        details.ultimateParentPartyCountryOfDomicile || ''
      this.quickRefDetails.ultimateParentPartyCountryOfRegistration.value =
        details.ultimateParentPartyCountryOfRegistration || ''
      this.quickRefDetails.anacreditApplicability.value = ![
        null,
        undefined
      ].includes(details.anaCreditApplicability)
        ? details.anaCreditApplicability === true
          ? 'Yes'
          : 'No'
        : ''
      this.quickRefDetails.priority = details.priority
      this.quickRefDetails.unsubscribe = details.unsubscribe
      this.quickRefDetails.isAutoEnrolled.value = details.isAutoEnrolled
      this.quickRefDetails.anacreditApplicabilityflag.value =
        details.anaCreditApplicability
      this.quickRefDetails.entityAnaCreditApplicabilityflag.value =
        details.entityAnaCreditApplicability
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-details {
  display: flex;
  width: 100%;
  height: auto;

  .menu-stepper-container {
    width: 251px;
    background-color: var(--dmp-menu-stepper-bg-color);
    padding: 12px 0px 12px 12px;
    height: auto;
  }

  .entity-details-main {
    width: calc(100% - 251px);

    .entity-details-main-container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
